import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/core';
import { showMessage } from 'react-native-flash-message';
import { notifications } from '../services/api';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

const EXPO_PUSH_TOKEN = '@report-apl:expo-push-token'; 

export const NotificationListener: React.FunctionComponent = () => {
  const navigation = useNavigation();

  const onNotificationReceived = useCallback((notification: Notifications.Notification) => {
    showMessage({
      type: "default",
      message: notification.request.content.title,
      floating: false,
      description: notification.request.content.body,
      duration: 5000,
      onPress: () => navigation.navigate('Notifications'),
      icon: 'default'
    });
  }, []);

  const onNotificationResponseReceived = useCallback((response) => {
    navigation.navigate('Notifications');
  }, []);

  useNotification({
    onNotificationReceived,
    onNotificationResponseReceived
  });

  return null;
}

export const useNotification = ({
  onNotificationReceived,
  onNotificationResponseReceived
}: UseNotificationProps | undefined) => {

  const [loading, setLoading] = useState<boolean>(true);
  const [ expoPushToken, setExpoPushToken ] = useState('');
  const notificationListener = useRef(null);
  const responseListener = useRef(null);

  useMemo(() => {
    (async () => {
      try {

        const storagedExpoPushToken = await AsyncStorage.getItem(EXPO_PUSH_TOKEN);
        if(storagedExpoPushToken){
          setExpoPushToken(storagedExpoPushToken);
          return;
        }
        
        const token: Notifications.ExpoPushToken = await Notifications.getExpoPushTokenAsync()
        if(token && token.data){
          setExpoPushToken(token.data);
          await persistToken(token.data);
          return;
        }

      } catch (error) {
        console.warn(error);
      } finally {
        setLoading(true)
      }
    })();
	}, []);

  useEffect(() => {
    if(!loading && !expoPushToken){
      registerForPushNotificationsAsync().then(async token => {
        setExpoPushToken(token);
        await persistToken(token);
        await notifications.registerExpoPushToken(token);
      });
    }

    if(onNotificationReceived){
      notificationListener.current = Notifications
        .addNotificationReceivedListener(onNotificationReceived);
    }

    if(onNotificationResponseReceived){
      responseListener.current = Notifications
        .addNotificationResponseReceivedListener(onNotificationResponseReceived);
    }
    return () => {
      if(notificationListener.current){
        Notifications.removeNotificationSubscription(notificationListener.current);
      }
      if(responseListener.current){
        Notifications.removeNotificationSubscription(responseListener.current);
      }
    };
  }, []);

  return {
    expoPushToken,
    loading
  };
}

async function persistToken(expoPushToken: string){
  await AsyncStorage.setItem(EXPO_PUSH_TOKEN, expoPushToken);
}

async function registerForPushNotificationsAsync() {
  let token: string;
  if ( Constants.isDevice ) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      alert('Falhou em recuperar o token de notificações push.');
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync()).data;
  } else {
    alert('Deve-se usar uma device físico para notificações push');
  }

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

  return token;
}



type UseNotificationProps = {
  /**
   * This listener is fired whenever a notification 
   * is received while the app is foregrounded
   */
  onNotificationReceived?: (notification: Notifications.Notification) => void;
  /** 
   * This listener is fired whenever a user taps on 
   * or interacts with a notification (works when app 
   * is foregrounded, backgrounded, or killed)
   */
  onNotificationResponseReceived?: (reponse: Notifications.NotificationResponse) => void;
}
import React from 'react';
import { Dimensions, Image, ScrollView, StyleSheet, View } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { CloseButton } from '../../components';
import { RootStackParamList } from '../../navigators/RootStack';
import { getImageURI } from '../../data/helper';

type Props = NativeStackScreenProps<RootStackParamList, 'ZoomImage'>;

export const ZoomImageScreen = ({ route, navigation }: Props) => {
  const { image } = route.params;

  // const scale = useRef(new Animated.Value(1)).current;
  // const translateX = useRef(new Animated.Value(0)).current;
  // const translateY = useRef(new Animated.Value(0)).current;
  
  // const onPinchEvent = Animated.event(
  //   [{ nativeEvent: { scale } }],
  //   { useNativeDriver: false }
  // );

  // const onPanEvent = Animated.event(
  //   [{ nativeEvent: { translationX: translateX, translationY: translateY } }],
  //   { useNativeDriver: true }
  // )

  return (
    <View style={styles.container}>

      <CloseButton 
        dark
        style={styles.close}
        onPress={() => navigation.goBack()} />

      <ScrollView 
        minimumZoomScale={1} 
        maximumZoomScale={5} 
        scrollEnabled={true} 
        showsHorizontalScrollIndicator={false} 
        showsVerticalScrollIndicator={false}
        bouncesZoom={true}
        centerContent={true}
        pinchGestureEnabled={true}
        >
      <Image
        style={styles.image}
        source={getImageURI(image)} />
      </ScrollView>


      {/* <PanGestureHandler onGestureEvent={onPanEvent}>
        <PinchGestureHandler onGestureEvent={onPinchEvent}> 

          <Animated.Image 
            source={{ uri }} 
            style={[styles.image, { transform: [
              { scale },
              { translateX },
              { translateY },
            ] }]} />

        </PinchGestureHandler>
      </PanGestureHandler> */}

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'black'
  },
  close:{
    position: 'absolute',
    top: 10,
    left: 10
  },
  image: {
    width: Dimensions.get('window').width,
    height: 300,
  },
});
import React from 'react';
import { StyleSheet, TouchableOpacity, View, Animated, Text } from 'react-native';
import { useNavigation } from '@react-navigation/core';
import { colors, fonts, specs } from '../../config/theme';
import { getImageURI } from '../../data/helper';
import { IChapterData } from '../../services/interfaces';
import { useRef } from 'hoist-non-react-statics/node_modules/@types/react';
const { ITEM_WIDTH, ITEM_HEIGHT, RADIUS, SPACING } = specs;

type SummaryListItemProps = {
  index: number,
  item: IChapterData,
  scale: Animated.AnimatedInterpolation,
  translateX: Animated.AnimatedInterpolation
}

export const SummaryListItem: React.FunctionComponent<SummaryListItemProps> = 
  ({ index, item, scale, translateX }) => {
  
  const navigation = useNavigation();
  const chapterIndex: string = String(index + 1);

  return (
    <TouchableOpacity
      key={`summary.item.${index}`}
      onPress={() => navigation.navigate('Chapter', { slug: item.slug, index: chapterIndex })} 
      style={styles.itemContainer}
      activeOpacity={0.8}>

      <Animated.View style={styles.card}>

        <Animated.View style={styles.cardShadow} />

        <Animated.Text style={[styles.title, {
          transform: [{ translateX }]
        }]}>{item.title}</Animated.Text>
        
        <Animated.Image 
          source={getImageURI(item.image)} 
          style={[styles.image, { transform: [{scale}] } ]} />

      </Animated.View>
        
      <View style={styles.numberContainer}>
        <Text style={styles.number}>{chapterIndex}</Text>
      </View>
      
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  itemContainer: {
    width: ITEM_WIDTH,
    height: ITEM_HEIGHT,
    margin: SPACING
  },
  title:{
    fontFamily: fonts.montserrat.bold,
    fontSize: 18,
    color: '#fff',
    fontWeight: '800',
    width: ITEM_WIDTH * 0.8,
    // textTransform: 'uppercase',
    position: 'absolute',
    bottom: SPACING + 10,
    left: SPACING,
    zIndex: 2
  },
  numberContainer:{
    position: 'absolute',
    top: SPACING,
    right: SPACING,
    width: 50,
    height: 50,
    borderRadius: 25,
    backgroundColor: 'rgba(255,255,255,0.4)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  number:{
    fontFamily: fonts.montserrat.bold,
    fontSize: 18,
    color: colors.primary,
  }, 
  card: { 
    width: ITEM_WIDTH,
    height: ITEM_HEIGHT,
    overflow: 'hidden', 
    borderRadius: RADIUS
  },
  cardShadow:{
    width: ITEM_WIDTH,
    height: ITEM_HEIGHT,
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1
  },
  image: {
    width: ITEM_WIDTH,
    height: ITEM_HEIGHT,
  }
});
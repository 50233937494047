import React, { useState, useEffect } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { BarCodeEvent, BarCodeScanner } from 'expo-barcode-scanner';
import { StatusBar } from 'expo-status-bar';
import * as WebBrowser from 'expo-web-browser';
import * as Haptics from 'expo-haptics';
import { colors, fonts, height, width } from '../../config/theme';
import { useNavigation } from '@react-navigation/core';
import { useAuth } from '../../contexts/Auth';
import { GradientButton } from '../../components';
import { getBottomSpace } from 'react-native-iphone-x-helper';
import { hapticsSelectionAsync } from '../../utils/haptic';

type QrData = {
  contentId: number;
  url?: string;
  screenName?: keyof ReactNavigation.RootParamList;
}

export const ScannerScreen: React.FC = () => {
  const navigation = useNavigation();
  const [hasPermission, setHasPermission] = useState(null);
  const [isReadedMessage, setIsReadedMessage] = useState(false);
  const [isReadedQrCode, setIsReadedQrCode] = useState(false);
  const { silentSignUp } = useAuth();

  const handleBarCodeScanned = async ({ type, data }: BarCodeEvent) => {
    if(isReadedMessage && !isReadedQrCode){
      if(type === BarCodeScanner.Constants.BarCodeType.qr && isQrData(data)){
        const content: QrData = JSON.parse(data);
        setIsReadedQrCode(true);
        
        if( content.screenName ){

          if( content.screenName === 'SilentLogin' ){
            await silentSignUp();
            return navigation.navigate('Home');
          }

          navigation.navigate(content.screenName);
          
        }else if( content.url ){
          await handleOpenBrowserAsync(content.url);
        }
      }
    }
  };

  const handleOpenBrowserAsync = async (url: string) => {
    await hapticsSelectionAsync();
    await WebBrowser.openBrowserAsync(url, {
      controlsColor: colors.light,
      dismissButtonStyle: 'close',
      enableBarCollapsing: true,
      enableDefaultShareMenuItem: false,
      readerMode: false,
      showTitle: false,
      toolbarColor: colors.primary
    });
  };

  useEffect(() => {
    (async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  if (hasPermission === null) {
    return <Text style={styles.text}>Solicitando permissão para câmera...</Text>;
  }
  if (hasPermission === false) {
    return <Text style={styles.text}>Sem acesso à câmera...</Text>;
  }

  return (
    <View style={styles.container}>

      {!isReadedMessage && 
        <View style={styles.messageContainer}>
          {/* <Text style={styles.title}>Use este leitor de QR Code para desbloquear o relatório</Text>
          <Text style={[styles.text, { marginBottom: 20}]}>Com esta função sua visita as dependências do prédio da Associação será única. Aponte a câmera para os QR codes espalhados pelas paredes e veja conteúdos inéditos.</Text>
          
          <Text style={styles.textBold}>Lugares para visitar:</Text>
          <Text style={styles.text}>- Recpeção</Text>
          <Text style={styles.text}>- Capela</Text>
          <Text style={styles.text}>- Escritório de departamentais</Text>
          <Text style={styles.text}>- Cozinha</Text>
          <Text style={styles.text}>- Caixa</Text>
          <Text style={styles.text}>- E outros...</Text> */}
          <Text style={styles.title}>Aponte a câmera para o QR Code para desbloquear o relatório</Text>
        
          <View style={styles.buttonContainer}>
            <GradientButton
              title="Entendi" 
              colors={['rgba(255,255,255,0.5)', 'rgba(255,255,255,0.2)']}
              onPress={() => setIsReadedMessage(true)} />
          </View>
        </View>}
      
      {isReadedQrCode && 
        <View style={styles.messageContainer}>
          <View style={styles.buttonContainer}>
            <GradientButton
              title="Ler outro QR Code" 
              colors={['rgba(255,255,255,0.5)', 'rgba(255,255,255,0.2)']}
              onPress={() => setIsReadedQrCode(false)} />
          </View>
        </View>}

      <BarCodeScanner
        onBarCodeScanned={handleBarCodeScanned}
        barCodeTypes={[BarCodeScanner.Constants.BarCodeType.qr]}
        style={StyleSheet.absoluteFillObject} />

      <StatusBar style="inverted" />
    </View>
  );
}

function isQrData( data: any ): data is QrData {
  try {
    if(!data || data === undefined) {
      return false;
    }
    const parsed: QrData = JSON.parse(data);
    return parsed.contentId && 
      ( parsed.screenName !== undefined || parsed.url !== undefined );
  } catch (error) {
    return false;
  }
}

const styles = StyleSheet.create({
  container:{
    flex:1,
  },
  messageContainer:{
    flex:1,
    justifyContent: 'center',
    position: 'absolute',
    width,
    height,
    zIndex: 2,
    padding: 20,
    backgroundColor: 'rgba(0,0,0,0.8)'
  },
  title:{
    fontFamily: fonts.montserrat.semi_bold,
    fontSize: 24,
    color: 'white',
    marginBottom: 20
  },
  text:{
    fontFamily: fonts.montserrat.regular,
    fontSize: 16,
    color: 'white'
  },
  buttonContainer:{
    position: 'absolute', 
    bottom: getBottomSpace() + 20, 
    width,
    justifyContent: 'center',
    alignContent: 'center',
    paddingHorizontal: 20
  },
  textBold:{
    fontFamily: fonts.montserrat.semi_bold,
    fontSize: 16,
    color: 'white'
  },
}); 


import React from 'react';
import { StyleSheet, Text, TextProps } from 'react-native';
import { colors, fonts, SPACING } from '../../config/theme';
import { ITitleData } from '../../services/interfaces';

export type TitleProps = Omit<ITitleData, "type"> & TextProps;

export const Title = ({ level, content, style, ...rest }: TitleProps) => {
  return (
    <Text 
      style={[
        styles[`title_${level}`], 
        styles.title, 
        style
      ]} 
      {...rest}
      >
      {content}
    </Text>
  )
}

const styles = StyleSheet.create({
  title:{
    paddingHorizontal: SPACING * 1.5,
    paddingBottom: SPACING * 1.2
  },
  title_1: {
    fontSize: 32,
    fontFamily: fonts.montserrat.bold,
    color: colors.primary,
    textTransform: 'uppercase',
    letterSpacing: -1,
    paddingTop: 30
  },
  title_2: {
    fontSize: 24,
    fontFamily: fonts.montserrat.semi_bold,
    color: colors.text,
    paddingTop: 40
    // textTransform: 'uppercase',
  },
  title_3: {
    fontSize: 18,
    fontFamily: fonts.montserrat.bold,
    color: colors.text,
    textTransform: 'uppercase',
    paddingTop: 30
  },
  title_4: {
    fontSize: 16,
    fontFamily: fonts.montserrat.bold,
    textTransform: 'none',
    color: colors.muted
  }
})
import { useNavigation } from '@react-navigation/core';
import React from 'react';
import { StyleSheet, View } from 'react-native';
// import * as WebBrowser from 'expo-web-browser';
import { colors, MAX_WIDTH } from '../../config/theme';
import { GradientButton } from '../../components';
import { MaterialCommunityIcons } from '@expo/vector-icons';
// import { hapticsSelectionAsync } from '../../utils/haptic';

export const AdminScreen = () => {
  const navigation = useNavigation();

  // const handleOpenBrowserAsync = async (url: string) => {
  //   await hapticsSelectionAsync();
  //   await WebBrowser.openBrowserAsync(url, {
  //     controlsColor: colors.light,
  //     dismissButtonStyle: 'close',
  //     enableBarCollapsing: true,
  //     enableDefaultShareMenuItem: false,
  //     readerMode: false,
  //     showTitle: false,
  //     toolbarColor: colors.primary
  //   });
  // };

  return (
    <View style={styles.container}>
      <GradientButton 
        title="Controle Remoto" 
        onPress={() => navigation.navigate('RemoteControl')}
        colors={['rgba(189, 46, 48, 1)', 'rgba(226, 90, 6, 0.75)']}
        icon={<MaterialCommunityIcons name="presentation" size={24} color="white" />} />
      <GradientButton 
        title="Enviar notificação" 
        onPress={() => navigation.navigate('SendNotifications')} 
        colors={['rgba(189, 46, 48, 1)', 'rgba(226, 90, 6, 0.75)']}
        icon={<MaterialCommunityIcons name="file-edit" size={24} color="white" />} />
    </View>
  );
}

const styles = StyleSheet.create({
  container:{
    backgroundColor: colors.bg,
    maxWidth: MAX_WIDTH,
    marginHorizontal: 'auto',
    flex: 1, 
    justifyContent: 'flex-end', 
    alignItems: 'center',
    padding: 12,
    paddingBottom: 40,
  },
  scroll: {
    flex: 1
  },
  button: {
    paddingVertical: 12
  }
});
import styled from 'styled-components/native';

import { colors, fonts } from '../../config/theme';

export const ItemContainer = styled.View`
  width: 100%;
  align-self: center;
  margin-bottom: 24px;
`;

export const Item = styled.View`
  background-color: rgba(0,0,0,0.04);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 16px;  
`;

export const Title = styled.Text`
  font-family: ${fonts.montserrat.bold};
  font-size: 16px;
  color: ${colors.text};
  letter-spacing: -0.44px;
  text-align: center;
  line-height: 22px;
`;

export const Message = styled.Text`
  font-family: ${fonts.montserrat.regular};
  font-size: 16px;
  color: ${colors.text};
  letter-spacing: -0.44px;
  text-align: center;
  line-height: 22px;
`;

export const DateText = styled.Text`
  font-family: ${fonts.montserrat.regular};
  font-size: 14px;
  color: #857D98;
  letter-spacing: -0.38px;
  text-align: center;
  line-height: 19px;
  margin-top: 10px;
`
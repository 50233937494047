import React, { useEffect, useState } from 'react';
import { ActivityIndicator, FlatList, Platform, StyleSheet, View } from 'react-native';
import { getStatusBarHeight } from 'react-native-iphone-x-helper'
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../navigators/RootStack';
import { IChapterItem } from '../../components/interfaces';
import { 
  Cover, 
  Title, 
  Paragraph, 
  Photo, 
  Carousel, 
  Graphic, 
  Person,
  BackButton
} from '../../components';
import { 
  ChapterItemType, 
  ICarouselData, 
  ICoverData, 
  IGraphicData, 
  IParagraphData, 
  IPersonData, 
  IPhotoData, 
  ITitleData 
} from '../../services/interfaces';
import { colors, MAX_WIDTH } from '../../config/theme';
import { chapters } from '../../services/api';
import { useNavigation } from '@react-navigation/core';

type Props = NativeStackScreenProps<RootStackParamList, 'Chapter'>;

export const ChapterScreen = ({ route }: Props): JSX.Element => {
  const { slug, index: chapterIndex } = route.params;
  const navigation = useNavigation();
  const [ items, setItems ] = useState<IChapterItem[]>([]);

  useEffect(() => {
    (async () =>{
      const data = await chapters.findBySlug(slug);
      const { content } = data;
      content.forEach((item, i) => {
        switch (item.type) {
          case ChapterItemType.COVER: setItems((prev) => [...prev, withCover(item as ICoverData, i, chapterIndex)] );
            break;
          case ChapterItemType.TITLE: setItems((prev) => [...prev, withTitle(item as ITitleData, i)] );
            break;
          case ChapterItemType.PARAGRAPH: setItems((prev) => [...prev, withParagraph(item as IParagraphData, i)] );
            break;
          case ChapterItemType.PHOTO: setItems((prev) => [...prev, withPhoto(item as IPhotoData, i)] );
            break;
          case ChapterItemType.CAROUSEL: setItems((prev) => [...prev, withCarousel(item as ICarouselData, i)] );
            break;
          case ChapterItemType.GRAPHIC: setItems((prev) => [...prev, withGraphic(item as IGraphicData, i)] );
            break;
          case ChapterItemType.PERSON: setItems((prev) => [...prev, withPerson(item as IPersonData, i)] );
          default:
            break;
        }
      });
    })();    
  }, [slug]);

  if(items.length === 0){
    return <ActivityIndicator />;
  }

  return (
    <View style={[StyleSheet.absoluteFillObject, styles.container]}>
      <BackButton onPress={() => navigation.goBack()} style={styles.backButton} />
      <FlatList
				data={items}
        style={{ paddingBottom: 200 }}
				renderItem={({item}) => item.render()}
				keyExtractor={item => item.key} />
    </View>
  );
}

const styles = StyleSheet.create({
  container:{
    maxWidth: MAX_WIDTH,
    marginHorizontal: 'auto',
    backgroundColor: colors.bg,
  },
  backButton: {
    top: getStatusBarHeight() + 10, 
    left: 10, 
    position: 'absolute'
  }
})

const withCover = ( item: ICoverData, index: number, chapterIndex: string ): IChapterItem => {
  return {
    key: `key-${index}`,
    render: () => (
      <Cover 
        index={chapterIndex} 
        title={item.title}
        subtitle={item.subtitle}
        image={item.image} />
    )
  }
}

const withTitle = ( item: ITitleData, index: number ): IChapterItem => {
  return {
    key: `key-${index}`,
    render: () => (
      <Title level={item.level} content={item.content} />
    )
  }
}

const withParagraph = ( item: IParagraphData, index: number ): IChapterItem => {
  return {
    key: `key-${index}`,
    render: () => (
      <Paragraph content={item.content} />
    )
  }
}

const withPhoto = ( item: IPhotoData, index: number ): IChapterItem => {
  return {
    key: `key-${index}`,
    render: () => (
      <Photo 
        image={item.image} 
        caption={item.caption} />
    )
  }
}

const withGraphic = ( item: IGraphicData, index: number ): IChapterItem => {
  return {
    key: `key-${index}`,
    render: () => (
      <Graphic
        title={item.title}
        image={item.image}
      />
    )
  }
}

const withCarousel = ( item: ICarouselData, index: number ): IChapterItem => {
  return {
    key: `key-${index}`,
    render: () => (
      <Carousel 
        title={item.title}
        items={item.items} />
    )
  }
}

const withPerson = (item: IPersonData, index: number): IChapterItem => {
  return {
    key: `key-${index}`,
    render: () => (
      <Person 
        name={item.name}
        biography={item.biography}
        image={ item.image } />
    )
  }
}
import React, { createContext, useContext, useCallback, useMemo, useState } from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { auth } from "../services/api";

interface IAuthContext {
  signed: boolean;
  loading: boolean,
  isAdmin: boolean,
  signUp?: (code: number) => Promise<boolean>;
  silentSignUp?: () => void;
  signOut?: () => Promise<void>;
}

const initialState = {
  loading: true,
  signed: false,
  isAdmin: false,
}

/**
 * Possibles values: 
 *    undefined (offline)
 *    client (logged)
 *    admin_client (logged with admin privilegies)
 */ 
const AUTH_STATUS = '@report-apl:auth-status'; 
 

const AuthContext = createContext<IAuthContext>(initialState);

const AuthProvider: React.FunctionComponent = ({ children }) => {
  const [signed, setSigned] = useState<boolean>(initialState.signed);
	const [loading, setLoading] = useState<boolean>(initialState.loading);
  const [isAdmin, setIsAdmin] = useState<boolean>(initialState.isAdmin);

  useMemo(() => {
    setLoading(true);
    AsyncStorage.getItem(AUTH_STATUS)
      .then(storagedStatus => {
        authenticate(storagedStatus);

      }).catch(error => {
        console.warn(error);
        
      }).finally(() => {
        setLoading(false);

      });
	}, []);

  const authenticate = useCallback((status: string | undefined) => {
    if(status === 'client'){
      setSigned(true);
    } else if(status === 'admin_client'){
      setSigned(true);
      setIsAdmin(true);
    }else{
      setSigned(false);
      setIsAdmin(false);
    }
  }, [setSigned, setIsAdmin]);

  async function signUp(code: number){
      setLoading(true); 
      const { data } = await auth(code);
      if(data){
        authenticate(data.status);
        await AsyncStorage.setItem(AUTH_STATUS, data.status);
        setLoading(false);
        return true;
      }
      setLoading(false);
      return false;
	}

  async function silentSignUp(){
    authenticate('client');
    await AsyncStorage.setItem(AUTH_STATUS, 'client');
    return true;
  }

  async function signOut(){
    authenticate(undefined);
    await AsyncStorage.clear();
  }

  return (
    <AuthContext.Provider value={{
      signed,
      isAdmin,
      loading,
      signUp,
      silentSignUp,
      signOut
    }}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
	const context = useContext(AuthContext);
	
	if (!context) {
		throw new Error('useAuth must be used within an AuthProvider.');
	}
	
	return context;
}

export { AuthProvider, useAuth };
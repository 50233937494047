import React, { useEffect } from 'react';
import { useNavigation } from '@react-navigation/core';
import { useAuth } from '../../contexts/Auth';
import { Loading } from '../../components/Loading';

export const SilentLoginScreen: React.FC = () => {
  const { silentSignUp } = useAuth();
  const navigation = useNavigation();

  useEffect(() => {
    silentSignUp();
    navigation.navigate('Home');
  }, []);

  return <Loading />;
}
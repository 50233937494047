import { images } from "./images";
import { IChapterMedia } from '../services/interfaces';
import { chapters } from "../services/api";

export function getImageURI( image: IChapterMedia ): number{
  if(image && image.id && image.slug){
    const key = `${image.id}-${image.slug}`;
    return images[key];
  }
  return require('../assets/noimage.png');
}

export function getChapterCovers(){
  return chapters.findAll()
    .map(chapter => getImageURI(chapter.image));
}

export function parseHtmlEntities(str: string) {
  if(str) return str
    .replace('<p>', '')
    .replace('</p>', '')
    .replace('&#8211;', '-');
  return str;
}
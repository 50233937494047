import React, { useEffect, useState } from 'react';
import { ImageBackground, StyleSheet, View } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { getStatusBarHeight } from 'react-native-iphone-x-helper'
import { useNavigation } from '@react-navigation/core';
import { showMessage } from 'react-native-flash-message';
import { BackButton, SummaryList, Title } from '../../components';

import { colors, MAX_WIDTH, specs } from '../../config/theme';
import { chapters } from '../../services/api';
import { IChapterData } from '../../services/interfaces';

export const SummaryScreen = () => {
  const navigation = useNavigation();
  const [ data, setData ] = useState<IChapterData[]>([]);

  useEffect(() => {
    (async () => {
      try {
        setData(await chapters.findAll());
      } catch (error) {
        console.warn(error);
        showMessage({
          message: "Não foi possível buscar o conteúdo",
          floating: true,
          description: `O servidor retornou com um erro: ${error.message}`,
          duration: 5000,
          type: "danger",
        });
      }
    })();
  }, []);

  return (
    <ImageBackground 
      source={require('../../assets/bg-summary.jpg')}
      style={styles.container}>

        <BackButton onPress={() => navigation.navigate('Home')} style={styles.backButton} dark />

        <View style={styles.innerContainer}>
          <Title level={1} content="Índice" style={styles.title} />
          <SummaryList data={data} />
        </View>
        <StatusBar style="inverted" />
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: MAX_WIDTH,
    marginHorizontal: 'auto',
    width: '100%',
    flex: 1,
    paddingTop: 120,
    justifyContent: 'flex-end'
  },
  innerContainer: {
    width: '100%',
    flex: 1,
    maxHeight: specs.ITEM_HEIGHT + 140,
    justifyContent: 'flex-end',
  },
  title:{
    color: colors.light
  },
  backButton: {
    top: getStatusBarHeight() + 10, 
    left: 10, 
    position: 'absolute'
  }
});
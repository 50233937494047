import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Title } from '../Title';
import { colors, fonts, getGraphicStyles, SPACING } from '../../config/theme';
import { IGraphicData } from '../../services/interfaces';
import { getImageURI } from '../../data/helper';

export type GraphicProps = Omit<IGraphicData, "type">;

export const Graphic = ({ title, image }: GraphicProps): JSX.Element => {
  return (
    <View style={styles.container}>
      {title && 
      <View style={styles.titleContainer}>
        <View style={styles.detail} />
        <Title content={title} level={4} style={styles.title} />
      </View>}
      <Image source={getImageURI(image)} style={getGraphicStyles(image)} />
    </View>
  );
}

const styles = StyleSheet.create({
  container:{
    paddingHorizontal: SPACING,
    marginTop: SPACING,
    marginBottom: SPACING * 2
  },
  titleContainer:{
    alignItems: 'center',
    flexDirection: 'row',
    height: 70,
    marginBottom: 10
  },
  title: {
    paddingLeft: 10, 
    paddingTop: 0,
    paddingBottom: 0
  },
  detail:{
    width: 70,
    height: 70,
    borderRadius: 70/2,
    backgroundColor: colors.secondary,
    position: 'absolute',
    left: -72,
    // top: -30
  },
  help: {
    fontFamily: fonts.inter.regular,
    fontSize: 12,
    color: colors.muted,
    textAlign: 'center',
    marginTop: SPACING
  }
})
export const slides = [
  require('../assets/slides/slide-1.jpg'),
  require('../assets/slides/slide-2.jpg'),
  require('../assets/slides/slide-3.jpg'),
  require('../assets/slides/slide-4.jpg'),
  require('../assets/slides/slide-5.jpg'),
  require('../assets/slides/slide-6.jpg'),
  require('../assets/slides/slide-7.jpg'),
  require('../assets/slides/slide-8.jpg'),
  require('../assets/slides/slide-9.jpg'),
  require('../assets/slides/slide-10.jpg'),
  require('../assets/slides/slide-11.jpg'),
  require('../assets/slides/slide-12.jpg'),
  require('../assets/slides/slide-13.jpg'),
  require('../assets/slides/slide-14.jpg'),
  require('../assets/slides/slide-15.jpg'),
  require('../assets/slides/slide-16.jpg'),
  require('../assets/slides/slide-17.jpg'),
  require('../assets/slides/slide-18.jpg'),
  require('../assets/slides/slide-19.jpg'),
  require('../assets/slides/slide-20.jpg'),
  require('../assets/slides/slide-21.jpg'),
  require('../assets/slides/slide-22.jpg'),
  require('../assets/slides/slide-23.jpg'),
  require('../assets/slides/slide-24.jpg'),
  require('../assets/slides/slide-25.jpg'),
  require('../assets/slides/slide-26.jpg'),
  require('../assets/slides/slide-27.jpg'),
  require('../assets/slides/slide-28.jpg'),
  require('../assets/slides/slide-29.jpg'),
  require('../assets/slides/slide-30.jpg'),
  require('../assets/slides/slide-31.jpg'),
  require('../assets/slides/slide-32.jpg'),
  require('../assets/slides/slide-33.jpg'),
  require('../assets/slides/slide-34.jpg'),
  require('../assets/slides/slide-35.jpg'),
  require('../assets/slides/slide-36.jpg'),
  require('../assets/slides/slide-37.jpg'),
  require('../assets/slides/slide-38.jpg'),
  require('../assets/slides/slide-39.jpg'),
  require('../assets/slides/slide-40.jpg'),
  require('../assets/slides/slide-41.jpg'),
  require('../assets/slides/slide-42.jpg'),
  require('../assets/slides/slide-43.jpg'),
  require('../assets/slides/slide-44.jpg'),
  require('../assets/slides/slide-45.jpg'),
  require('../assets/slides/slide-46.jpg'),
  require('../assets/slides/slide-47.jpg'),
  require('../assets/slides/slide-48.jpg'),
  require('../assets/slides/slide-49.jpg'),
  require('../assets/slides/slide-50.jpg'),
  require('../assets/slides/slide-51.jpg'),
  require('../assets/slides/slide-52.jpg'),
  require('../assets/slides/slide-53.jpg'),
  require('../assets/slides/slide-54.jpg'),
]
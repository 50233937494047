import React from 'react';
import { TouchableOpacityProps } from 'react-native';

import { IconContainer, BackIcon } from './styles';

interface Props extends TouchableOpacityProps {
  dark?: boolean
}

export const BackButton = ({ dark = false, ...props}: Props) => {
  return (
    <IconContainer dark={dark} {...props}>
      <BackIcon dark={dark} />
    </IconContainer>
  );
}
import React, { useState, useEffect, useCallback } from 'react';
import { ActivityIndicator, Platform } from 'react-native';
import { LinkingOptions, NavigationContainer } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { PusherProvider } from "@harelpls/use-pusher";
import AsyncStorage from '@react-native-async-storage/async-storage';
import FlashMessage from "react-native-flash-message";

import RootStack, { RootStackParamList } from './RootStack';
import { AuthProvider } from '../contexts/Auth';
import { NotificationListener } from '../hooks/useNotification';

const PERSISTENCE_KEY = 'NAVIGATION_STATE';

const PUSHER_CONFIG = {
  clientKey: "465df4d4a5c4beb7ab4c",
  cluster: "us2",
  triggerEndpoint: "https://relatorio-apl-api.infrateam.info/api/pusher/trigger",
  // authEndpoint: "https://relatorio-apl-api.infrateam.info/api/pusher/auth",
  auth: {
    headers: { Authorization: "Bearer signedin" },
  },
}

const prefix = Linking.createURL('/');

const AppNavigator: React.FC = ({ children }) => {
  const [isReady, setIsReady] = useState(__DEV__ ? false : true);
  const [initialState, setInitialState] = useState();
  const linking: LinkingOptions<RootStackParamList> = {
    prefixes: [prefix],
    config: {
      initialRouteName: 'Home',
      screens: {
        Home: '/',
        Presentation: '/presentation',
        Summary: '/summary',
        Login: '/login',
        Admin: '/admin',
        Chapter: '/chapter',
        Notifications: '/notifications',
        Scanner: '/scanner',
        ScannerWall: '/scanner-wall',
        SendNotifications: '/admin/send-notifications',
        ZoomImage: '/zoom'
      }
    },
  };

  useEffect(() => {
    const restoreState = async () => {
      try {
        const initialUrl = await Linking.getInitialURL();

        if (Platform.OS !== 'web' && initialUrl == null) {
          // Only restore state if there's no deep link and we're not on web
          const savedStateString = await AsyncStorage.getItem(PERSISTENCE_KEY);
          const state = savedStateString ? JSON.parse(savedStateString) : undefined;

          if (state !== undefined) {
            setInitialState(state);
          }
        }
      } finally {
        setIsReady(true);
      }
    };

    if (!isReady) {
      restoreState();
    }
  }, [isReady]);

  const handleStateChange = useCallback(async (state) => {
    await AsyncStorage.setItem(PERSISTENCE_KEY, JSON.stringify(state));
  },[]);

  if (!isReady) {
    return <ActivityIndicator />;
  }
  
  return (
    <AuthProvider>
      <PusherProvider {...PUSHER_CONFIG}>
        <NavigationContainer
          initialState={initialState} 
          linking={linking}
          onStateChange={handleStateChange}>
          

          <RootStack />
          <NotificationListener />
          
          {children}
          
        </NavigationContainer>
        <FlashMessage position="top" />
      </PusherProvider>
    </AuthProvider>
  )
}

export default AppNavigator;
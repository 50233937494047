import React from 'react';
import { Image, Text, View, StyleSheet } from 'react-native';
import { Paragraph } from '../Paragraph';
import { colors, fonts } from '../../config/theme';
import { getImageURI } from '../../data/helper';
import { IPersonData } from '../../services/interfaces';

export type PersonProps = Omit<IPersonData, "type">;

export const Person = ({ name, image, biography }: PersonProps): JSX.Element => {
  return (
    <View style={styles.container}>
      
      <View style={styles.headingContainer}>
        <View style={styles.avatarContainer}>
          <Image source={getImageURI(image)} style={styles.avatar} />
          <View style={styles.avatarShadow} />
        </View>

        <Text style={styles.name}>{name}</Text>
      </View>

      <Paragraph content={biography} />

    </View>
  );
}

const styles = StyleSheet.create({
  container:{
    marginTop: 40,
    paddingBottom: 40
  },
  headingContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 20
  },
  avatarContainer:{
    width: 117,
    height: 117,
    marginRight: 20,
    marginLeft: -20
  },
  avatar: {
    width: 117,
    height: 117,
    borderRadius: 117 / 2,
    position: 'absolute',
    zIndex: 2
  },
  avatarShadow:{
    width: 117,
    height: 117,
    borderRadius: 117 / 2,
    backgroundColor: colors.secondary,
    position: 'absolute',
    top: -12,
    left: -3
  },
  name:{
    fontSize: 24,
    fontFamily: fonts.montserrat.semi_bold,
    color: colors.text,
    width: 220,
    paddingRight: 20,
  }
})
import React, { useRef } from 'react';
import { Animated } from 'react-native';
import { width, specs } from '../../config/theme';
import { IChapterData } from '../../services/interfaces';
import { SummaryListItem } from './SummaryListItem';
const { ITEM_WIDTH, FULL_SIZE } = specs;

export type SummaryListProps = {
  data: IChapterData[]
}

export const SummaryList = ({ data }: SummaryListProps) => {

  const scrollX = useRef(new Animated.Value(0)).current;

  const renderItem = ({ item, index }) => {
    const inputRange = [
      (index - 1) * FULL_SIZE, 
      index * FULL_SIZE, 
      (index + 1) * FULL_SIZE 
    ];
    const translateX = scrollX.interpolate({ 
      inputRange, 
      outputRange: [ITEM_WIDTH, 0, -ITEM_WIDTH],
      extrapolate: 'clamp'
    });
    const scale = scrollX.interpolate({
      inputRange,
      outputRange: [1, 1.2, 1],
      extrapolate: 'clamp'
    });
    return (
      <SummaryListItem
        index={index}
        item={item}
        scale={scale}
        translateX={translateX} />
    )
  }

  return (
    <Animated.FlatList
      data={data}
      keyExtractor={item => item.id}
      horizontal
      showsHorizontalScrollIndicator={false}
      snapToInterval={FULL_SIZE}
      decelerationRate="fast"
      onScroll={Animated.event(
        [{nativeEvent: { contentOffset: { x: scrollX } } }],
        { useNativeDriver: true }
      )}
      renderItem={renderItem}
      contentContainerStyle={{
        paddingRight: width - FULL_SIZE
      }}
    />
  );
}
import React from 'react';
import { View, StyleSheet, ImageBackground, Platform } from 'react-native';
import { getStatusBarHeight, getBottomSpace } from 'react-native-iphone-x-helper'
import { useNavigation } from '@react-navigation/core';
import { StatusBar } from 'expo-status-bar';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import { AdminButton, CloseButton, NotificationButton, GradientButton, Loading } from '../../components';
import { useAuth } from '../../contexts/Auth';
import { hapticsSelectionAsync } from '../../utils/haptic';
import { MAX_WIDTH } from '../../config/theme';

export const HomeScreen: React.FunctionComponent = () => {
  const navigation = useNavigation();
  const { signed, isAdmin, loading, signOut } = useAuth();

  const handleNavigateAsync = async (screen: keyof ReactNavigation.RootParamList) => {
    await hapticsSelectionAsync();
    navigation.navigate(screen);
  }

  if(loading){
    return <Loading />;
  }

  return (
    <ImageBackground
      source={require('../../assets/bg-home.jpg')}
      style={styles.container}>

      <View style={styles.innerContainer}>        
        
        {signed ?
          <>
            <CloseButton onPress={() => signOut()} style={styles.closeButton} />
            <NotificationButton onPress={() => handleNavigateAsync('Notifications')} style={styles.notificationButton} />
            {isAdmin && <AdminButton onPress={() => handleNavigateAsync('RemoteControl')} style={styles.admButton} />}
            
            <GradientButton 
              title="Segunda Tela" 
              onPress={() => handleNavigateAsync("Presentation")}
              colors={['rgba(189, 46, 48, 1)', 'rgba(226, 90, 6, 0.75)']}
              icon={<MaterialCommunityIcons name="presentation" size={24} color="white" />} />
    
            <GradientButton   
              title="Relatório" 
              onPress={() => handleNavigateAsync("Summary")}
              icon={<MaterialCommunityIcons name="format-list-text" size={24} color="white" />} />
          </>
        : 
          <>
            { Platform.OS !== 'web' &&
            <GradientButton 
              title="Login com QR Code"
              onPress={() => handleNavigateAsync("Scanner")}
              icon={<MaterialCommunityIcons name="qrcode" size={24} color="white" />} /> }
            <GradientButton 
              title="Login com código" 
              onPress={() => handleNavigateAsync("Login")}
              icon={<MaterialCommunityIcons name="lock" size={24} color="white" />} />
          </>
        }
      </View>

      <StatusBar style="auto" />
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: MAX_WIDTH,
    marginHorizontal: 'auto',
    width: '100%',
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  innerContainer: {
    width: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingHorizontal: 15,
    paddingBottom: getBottomSpace() + 15,
  },
  closeButton:{
    top: getStatusBarHeight() + 10, 
    right: 10, 
    position: 'absolute'
  },
  notificationButton:{
    top: getStatusBarHeight() + 12, 
    right: 60, 
    position: 'absolute'
  },
  admButton:{
    top: getStatusBarHeight() + 10, 
    right: 110, 
    position: 'absolute'
  }
});
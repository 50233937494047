import React from 'react';
import { Image, StyleSheet, Text, View } from "react-native";
import { getStatusBarHeight } from "react-native-iphone-x-helper";
import { colors, fonts, height, width } from "../../config/theme";
import { slides } from "../../data/sliders";

type SlideProps = {
  loading: boolean;
  index: number;
}

export const Slide: React.FunctionComponent<SlideProps> = ({ loading, index }) => {

  if( loading ){ 
    return <Text style={styles.text}>Abrindo...</Text>;
  }
  
  if( index <= 0){
    return (
      <View style={{ 
          padding: 20
        }}>
        <Text style={styles.title}>Use a Segunda Tela em sua próxima reunião...</Text>
        <Text style={[styles.text, { marginBottom: 20}]}>Com esta função você terá uma experiência diferente ao participar das reuniões promovidas pela Associação.</Text>
        <Text style={styles.textBold}>Próxima reunião:</Text>
        <Text style={styles.text}>Domingo, 28/11: Quadrienal APL</Text>
      </View>
    );
  }

  return (
    <Image 
      source={slides[index-1]}
      style={[StyleSheet.absoluteFillObject, styles.image]} />
  )
}

const styles = StyleSheet.create({
  title:{
    fontFamily: fonts.montserrat.semi_bold,
    fontSize: 24,
    color: colors.dark,
    marginBottom: 20
  },
  text:{
    fontFamily: fonts.montserrat.regular,
    fontSize: 16,
    color: colors.dark
  },
  textBold:{
    fontFamily: fonts.montserrat.semi_bold,
    fontSize: 16,
    color: colors.dark
  },
  image:{
    marginTop: getStatusBarHeight(),
    width: width,
    height: height - getStatusBarHeight()
  },
});
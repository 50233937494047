import React, { useCallback, useState } from 'react';
import { useNavigation } from '@react-navigation/core';
import { ActivityIndicator, LayoutChangeEvent, StyleSheet, Text, View, Platform } from 'react-native';
import { CodeField, Cursor, useBlurOnFulfill, useClearByFocusCell } from 'react-native-confirmation-code-field';
import { showMessage } from 'react-native-flash-message';
import * as Haptics from 'expo-haptics';
import { CloseButton, Title } from '../../components';
import { useAuth } from '../../contexts/Auth';
import { colors } from '../../config/theme';

import { Background, ButtonContainer, ButtonBackground, Label } from './styles';

const CELL_COUNT = 4;

export const LoginScreen = () => {

  const [loading, setLoading] = useState(false);
  const { signUp } = useAuth();
  const navigation = useNavigation();

  const [ value, setValue ] = React.useState('');

  const ref = useBlurOnFulfill({value, cellCount: CELL_COUNT});
  const [ props, getCellOnLayoutHandler ] = useClearByFocusCell({ value, setValue });

  const handleSubmit = useCallback( async () => {
    if(value.length === CELL_COUNT && !loading){
      setLoading(true);
      try {
        if(await signUp(Number(value))){
          return navigation.navigate("Home");
        }
        
      } catch (error) {
        console.warn(error);
        await Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);
        showMessage({
          message: "Verifique o código digitado.",
          floating: true,
          description: `O servidor retornou com um erro: ${error.message}`,
          duration: 5000,
          type: "danger"
        });
        setLoading(false);
      }
    }
  }, [value]);
  
  return (
    <Background>
      <View style={{flex:0.6, justifyContent: 'center', alignItems: 'center', padding: 20}}>
        <CloseButton onPress={() => navigation.navigate('Home')} style={styles.closeButton} />
        
        <Title level={2} content="Abra o relatório" style={styles.title} />
        <Text style={{ textAlign: "center" }}>
          Informe o código para desbloquear o acesso ao relatório.
        </Text>

        <CodeField
          ref={ref}
          {...props}
          value={value}
          onChangeText={setValue}
          autoFocus={true}
          cellCount={CELL_COUNT}
          rootStyle={styles.codeFieldRoot}
          textContentType="oneTimeCode"
          autoCapitalize="none"
          keyboardType={'number-pad'}
          renderCell={(cell) => renderCodeFieldCell(cell, () => getCellOnLayoutHandler(cell.index)) } />

        <ButtonContainer onPress={handleSubmit}>
          <ButtonBackground disabled={value.length !== CELL_COUNT}>
            {loading ? <ActivityIndicator color="white" /> : <Label>Validar</Label> }
          </ButtonBackground>
        </ButtonContainer>

      </View>
    </Background>
  );
}

const renderCodeFieldCell = ({ index, symbol, isFocused }, onLayout: (event: LayoutChangeEvent) => void) => {
  return (
    <View
      key={index}
      onLayout={onLayout}
      style={[styles.cellRoot, isFocused && styles.focusCell]}>
      <Text style={styles.cellText}>
          {symbol || (isFocused ? <Cursor /> : null)}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  title:{
    textAlign: 'center',
    color: colors.alt,
    marginBottom: 20
  },
  closeButton:{
    top: Platform.OS === 'android' ? 30 : 10, 
    left: 10, 
    position: 'absolute'
  },
  input: {
    width: 200,
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
  codeFieldRoot: {
    marginTop: 20,
    marginBottom: 40,
    width: 300,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  cellRoot: {
    width: 60,
    height: 90,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderRadius: 10,
  },
  cellText: {
    // color: colors.text,
    // fontFamily: fonts.Anonymous,
    fontSize: 36,
    textAlign: 'center',
  },
  focusCell: {
    borderColor: colors.alt,
    borderWidth: 2,
  },
});
import React from 'react';
import { TouchableOpacityProps } from 'react-native';

import { IconContainer, NotificationIcon } from './styles';

interface Props extends TouchableOpacityProps {
  dark?: boolean
}

export const NotificationButton = ({ dark = false, ...props}: Props) => {
  return (
    <IconContainer dark={dark} {...props}>
      <NotificationIcon dark={dark} />
    </IconContainer>
  );
}
import { LinearGradient } from "expo-linear-gradient";
import styled from "styled-components/native";
import { colors, MAX_WIDTH } from "../../config/theme";

export const Background = styled(LinearGradient).attrs({
  colors: ['#F3F2CB','#F1B697']
})`
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  margin: auto;
  flex: 1;
`;

export const ButtonContainer = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8
})`
  width: 100%;
  margin: 10px 0;
  padding: 0 20px;
`;

interface ButtonBackgroundProps {
  disabled: boolean
}
export const ButtonBackground = styled.View<ButtonBackgroundProps>`
  background-color: ${colors.alt};
  padding: 16px 18px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
  opacity: ${(props) => props.disabled ? 0.3 : 1};
`;

export const Label = styled.Text`
  font-size: 20px;
  font-weight: bold;
  color: #FFF;
  letter-spacing: -0.55px;
`;

import styled from 'styled-components/native';
import { LinearGradient } from 'expo-linear-gradient';
import { Entypo } from '@expo/vector-icons';
// import { fonts, maxWidth } from '../../styles';

export const ButtonContainer = styled.TouchableOpacity`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0;
`;

export const ButtonBackground = styled(LinearGradient).attrs({
  start: [0,1], end: [1,0] 
})`
  padding: 14px 18px;
  flex-direction: row;
  align-items: center;
`;


export const Label = styled.Text`
  font-size: 20px;
  font-weight: bold;
  color: #FFF;
  letter-spacing: -0.55px;
`;

export const IconContainer = styled.View`
  width:46px;
  height:46px;
  border-radius:23px;
  background-color: 'rgba(255,255,255,0.2)';
  margin-right: 10px;
  align-items: center;
  justify-content: center;
`;

export const Arrow = styled(Entypo).attrs({
  name: "chevron-right", size:26, color: "white"
})`
  margin-left: auto;
`
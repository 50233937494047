import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/core';
import * as Haptics from 'expo-haptics';
import { useKeepAwake } from 'expo-keep-awake';
import { getStatusBarHeight } from 'react-native-iphone-x-helper';
import { useChannel, useEvent } from '@harelpls/use-pusher';
import { BackButton, Slide } from '../../components';
import { slides } from '../../data/sliders';
import { MAX_WIDTH } from '../../config/theme';
import { api } from '../../services/api';
import { showMessage } from 'react-native-flash-message';
import { hapticsImpactAsync, hapticsNotificationAsync } from '../../utils/haptic';

export const PresentationScreen: React.FC = () => {
  useKeepAwake();
  const navigation = useNavigation();
  const channel = useChannel('presentation');
  const [ loading, setLoading ] = useState<boolean>(true);
  const [ index, setIndex ] = useState<number>(0);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data: newIndex } = await api.get('/api/pusher/start');
        if(newIndex !== undefined){
          setIndex(newIndex);
          await hapticsNotificationAsync(Haptics.NotificationFeedbackType.Success);
        }
      } catch (error) {
        console.log(error);
        await hapticsNotificationAsync(Haptics.NotificationFeedbackType.Error);
        showMessage({
          message: "Não foi possível sincronizar a apresentação",
          floating: true,
          description: `O servidor retornou com um erro: ${error.message}`,
          duration: 5000,
          type: "danger"
        });
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  
  useEvent<number>(channel, 'goto', async (newIndex) => {
    if(newIndex >= 0 && newIndex <= slides.length){
      setIndex(newIndex);
      await hapticsImpactAsync(Haptics.ImpactFeedbackStyle.Heavy);
    } 
  });

  return (
    <View style={styles.container}>
      <BackButton onPress={() => navigation.navigate('Home') } style={styles.backButton} />
      <Slide loading={loading} index={index} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: MAX_WIDTH,
    marginHorizontal: 'auto',
    width: '100%',
    flex: 1,
    backgroundColor:'#F7F6E1',
    justifyContent: 'center',
    alignItems: 'center'
  },
  backButton: {
    top: getStatusBarHeight() + 10, 
    left: 10, 
    position: 'absolute'
  }
})
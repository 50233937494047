import { Dimensions, ImageStyle, StyleProp } from "react-native";
import { IChapterMedia } from "../services/interfaces";

export const { width: windowWidth, height } = Dimensions.get('window');
export const MAX_WIDTH = 560;
export const width = windowWidth > MAX_WIDTH ? MAX_WIDTH : windowWidth;
export const SIZE = 30;
export const SPACING = 12;

const s = width * 0.58;

export const specs = {
  ITEM_WIDTH: s,
  ITEM_HEIGHT: s * 1.8,
  RADIUS: 14,
  SPACING,
  FULL_SIZE: s + ( SPACING * 2 ),
}

export const fonts = {
  montserrat: {
    regular: 'Montserrat_400Regular',
    semi_bold: 'Montserrat_600SemiBold',
    bold: 'Montserrat_700Bold',
  },
  inter: {
    regular: 'Inter_400Regular',
    bold: 'Inter_700Bold'
  }
}

export const colors = {
  bg: '#FCFCF5',
  text: '#302E34',
  primary: '#664A8C',
  secondary: '#F27B50',
  muted: '#8C8B82',
  light: '#F2EBC4',
  dark: '#8C2727',
  alt: '#D92B2B'
}

export function getGraphicStyles( image: IChapterMedia ): StyleProp<ImageStyle> {
  const newWidth = width - (SPACING * 3);
  const newHeight = ( newWidth / image.width ) * image.height;
  return {
    width: newWidth,
    height: newHeight,
    borderRadius: specs.RADIUS,
    alignSelf: 'center'
  }
}

export function getPhotoStyles( image: IChapterMedia ): StyleProp<ImageStyle> {
  return getPhotoSizes(image);
}

export function getPhotoSizes( image: IChapterMedia ): { width: number; height: number; } {
  const newWidth = width;
  const newHeight = ( newWidth / image.width ) * image.height;
  return {
    width: newWidth,
    height: newHeight
  }
}

import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { colors, fonts } from '../../../config/theme';

export const Label = styled.Text`
    font-family: ${fonts.montserrat.regular};
    font-size: 16px;
    color: ${colors.muted};
    letter-spacing: -0.3px;
    margin-bottom: 8px;
`

export const InputText = styled.TextInput`
    padding: ${Platform.OS === 'web' ? '10px' : '10px 0' };
    border-bottom-width: 1px;
    border-bottom-color: ${colors.text};
    font-family: ${fonts.montserrat.regular};
    font-size: 17px;
    color: ${colors.text};
`
import React, { useCallback } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { colors, fonts, getPhotoStyles, SPACING } from '../../config/theme';
import { useNavigation } from '@react-navigation/core';
import { IChapterMedia, IPhotoData } from '../../services/interfaces';
import { getImageURI, parseHtmlEntities } from '../../data/helper';

export type PhotoProps = Omit<IPhotoData, "type">;

export const Photo = ({ image, caption }: PhotoProps): JSX.Element => {
  const navigation = useNavigation();

  const onPress = useCallback((img: IChapterMedia) => {
    navigation.navigate('ZoomImage', { image: img });
  }, [navigation]);

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={() => onPress(image)}>
        <Image source={getImageURI(image)} style={[getPhotoStyles(image)]} />
      </TouchableOpacity>
      {caption && <Text style={styles.caption}>{ parseHtmlEntities(caption) }</Text>}
    </View>
  );
}

const styles = StyleSheet.create({
  container:{
    marginVertical: SPACING * 2
  },
  caption: {
    fontFamily: fonts.inter.regular,
    fontSize: 12,
    color: colors.muted,
    textAlign: 'center',
    padding: SPACING,
  }
})
import * as Haptics from 'expo-haptics';
import { Platform } from 'react-native';

export async function hapticsSelectionAsync(){
  if( Platform.OS !== 'web'){
    await Haptics.selectionAsync();
  }
}
export async function hapticsNotificationAsync( notificationFeedbackType: Haptics.NotificationFeedbackType){
  if( Platform.OS !== 'web' ){
    await Haptics.notificationAsync(notificationFeedbackType);
  }
}
export async function hapticsImpactAsync( impactFeedbackStyle: Haptics.ImpactFeedbackStyle ){
  if( Platform.OS !== 'web' ){
    await Haptics.impactAsync(impactFeedbackStyle);
  }
}
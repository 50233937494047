import React, { useState, useEffect } from 'react';
import { showMessage } from 'react-native-flash-message';
import { ScrollView, StyleSheet } from 'react-native';
import { notifications } from '../../services/api';
import {
  ItemContainer,
  Item,
  Title,
  Message,
  DateText
} from './styles';
import { Loading } from '../../components/Loading';
import { colors, MAX_WIDTH, SPACING } from '../../config/theme';

interface INotificationData {
  _id: string;
  title: string;
  message: string;
  createdAt: Date;
}

export const NotificationsScreen: React.FunctionComponent = () => {
  const [ loading, setLoading ] = useState(true);
  const [ items, setItems ] = useState<INotificationData[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await notifications.getNotifications();
        setItems(data);
        
      } catch (error) {
        showMessage({
					message: error,
					type: 'danger',
					icon: 'danger'
				});

      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if(loading){
    return <Loading />
  }

  return (
    <ScrollView style={styles.container}>
      {items?.map((item) => (
        <ItemContainer key={item._id}>
          <Item>
            <Title>{item.title}</Title>
            <Message>{item.message}</Message>
          </Item>
          <DateText>{String(item.createdAt)}</DateText>
        </ItemContainer>
      ))}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container:{
    maxWidth: MAX_WIDTH,
    marginHorizontal: 'auto',
    backgroundColor: colors.bg,
    padding: SPACING
  }
})
import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import { colors } from '../../config/theme';

export const Loading: React.FC = () => {
  return (
    <View style={{ 
      flex: 1, 
      backgroundColor: colors.bg,
      justifyContent: 'center',
      alignItems: 'center'
      }}>
      <ActivityIndicator color={colors.primary} />
    </View>
  );
}
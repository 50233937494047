import styled from "styled-components/native";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { colors } from "../../config/theme";

interface NavIconProps {
    dark: boolean;
}

export const IconContainer = styled.TouchableOpacity`
    position: relative;
    /* top: 10px;
    left: 10px; */
    width: 40px;
    height: 40px;
    border-radius:23px;
    background-color: ${(props: NavIconProps) => props.dark ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)'};
    align-items: center;
    justify-content: center;
    z-index: 100;
`;

export const CloseIcon = styled(Ionicons).attrs({
    name: "close-outline", 
    size: 28
})`
    color: ${(props: NavIconProps) => props.dark ? colors.light : colors.primary};
`;

export const BackIcon = styled(Ionicons).attrs({
    name: "chevron-back", 
    size: 28
})`
    color: ${(props: NavIconProps) => props.dark ? colors.light : colors.primary}
`;

export const NotificationIcon = styled(Ionicons).attrs({
    name: "notifications-outline", 
    size: 28
})`
    color: ${(props: NavIconProps) => props.dark ? colors.light : colors.primary}
`;

export const AdmIcon = styled(MaterialCommunityIcons).attrs({
    name: "security", 
    size: 28
})`
    color: ${(props: NavIconProps) => props.dark ? colors.light : colors.primary}
`
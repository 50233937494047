import axios from "axios";
import { IChapterData } from "./interfaces";

import DATA from '../data/chapters.json'

export const api = axios.create({
  baseURL: "https://relatorio-apl-api.infrateam.info",
});
export const apiPlesk = axios.create({
  baseURL: "https://api-relatorio-apl.infrateam.info",
})

export const auth = async (code: number) => {
  return await api.get(`/api/auth?code=${code}`);
}

export const notifications = {
  async sendNotification(title: string, message: string) {
    return await api.post(`/api/push`, { title, message });
  },
  async getNotifications() {
    return await api.get(`/api/push`);
  },
  async registerExpoPushToken(expoPushToken: string){
    return await api.post(`/api/push/token`, { expoPushToken });
  }
}

export const chapters = {
  findAll() : IChapterData[]{
    return DATA.map(chapter => ({
      id: chapter.id,
      chapterId: chapter.chapterId,
      title: chapter.title,
      slug: chapter.slug,      
      order: chapter.order,
      image: chapter.image,
    }));
  },
  findBySlug(slug: string) : IChapterData{
    const chapters = DATA.filter(chapter => chapter.slug === slug);
    return chapters[0];
  },
}

export const presentation = {
  async start(){
    return apiPlesk.get('/presentation/index');
  },
  async trigger( index: number ){
    return apiPlesk.post('/presentation/trigger', {
      channelName: 'presentation', 
      eventName: 'goto', 
      data: index,
    });
  }
}
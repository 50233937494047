import React, { useState, useEffect, useCallback } from 'react';
import { StyleSheet, View, TouchableOpacity, ActivityIndicator, Text } from 'react-native';
import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import { Loading, Slide } from "../../../components";
import { slides } from '../../../data/sliders';
import { colors, fonts, MAX_WIDTH } from '../../../config/theme';
import { getBottomSpace } from 'react-native-iphone-x-helper';
import { StatusBar } from 'expo-status-bar';
import { presentation } from '../../../services/api';

const NUMBER_OF_SLIDES = slides.length;

export const RemoteControlScreen: React.FunctionComponent = () => {
  const [ loading, setLoading ] = useState(true);
  const [ index, setIndex ] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const { data: { slide } } = await presentation.start();
        setIndex(slide)
        setLoading(false);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false)
      }
    })();
  }, []);

  useEffect(() => {
    (async ()=> {
      setLoading(true);
      try {
        await presentation.trigger(index);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [index]);

  return (
    <View style={styles.container}>

      <View style={styles.content}>
        {loading ? <Loading /> : <Slide loading={false} index={index} />}
      </View>

      <View style={styles.controllers}>

        <TouchableOpacity
          style={styles.restart}
          onPress={() => setIndex(0)}>
          <MaterialCommunityIcons name="skip-previous-circle" size={32} color="white" />
        </TouchableOpacity>
        
        {!loading && 
        <TouchableOpacity
          onPress={() => setIndex(prev => prev - 1 >= 0 ? prev - 1 : prev)}>
          <AntDesign name="leftcircle" size={50} color="white" />
        </TouchableOpacity>}

        <View style={styles.info}>
          { loading ? 
            <ActivityIndicator color="#FFFFFF" size={34} /> 
          :
            <Text style={styles.infoText}>{ index === 0 ? `-` : index }</Text>
          }
        </View>

        {!loading && 
        <TouchableOpacity
          onPress={() => setIndex(prev => prev + 1 <= NUMBER_OF_SLIDES ? prev + 1 : prev)}>
          <AntDesign name="rightcircle" size={50} color="white" />
        </TouchableOpacity>}
      </View>
      <StatusBar style="inverted" />
    </View>
  )

}

const styles = StyleSheet.create({
  container:{
    maxWidth: MAX_WIDTH,
    marginHorizontal: 'auto',
    width: '100%',
    flex: 1,
    backgroundColor: colors.bg
  },
  content:{
    flex: 1,
    backgroundColor: colors.bg,
    justifyContent: 'center',
    alignItems: 'center',
    // transform: [{ scale: 0.9 }]
  },
  controllers:{
    backgroundColor: 'rgba(0,0,0,0.5)',
    width: '100%',
    height: 120,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 20,
    paddingBottom: getBottomSpace() + 10,
    position: 'absolute',
    bottom: 0
  },
  restart:{
    position: 'absolute',
    top: 32,
    left: 30
  },
  info:{
    width: 120,
    justifyContent: 'center',
    alignItems: 'center'
  },
  infoText:{
    color: 'white',
    fontSize: 18,
    fontFamily: fonts.montserrat.bold
  }
})
import React, { useState, useCallback } from 'react';
import { View, ScrollView } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { GradientButton } from '../../../components';
import { notifications } from '../../../services/api';

import { Label, InputText } from './styles';

export const SendNotificationScreen: React.FC = () => {

  const [ loading, setLoading ] = useState(false);
  const [ title, setTitle ] = useState('');
  const [ message, setMessage ] = useState('');

  const handleSubmit = useCallback( async () => {
    setLoading(true);

    if( title || message ){
      showMessage({
        message: `Todos os campos são obrigatórios`,
        type: 'danger',
        icon: 'danger'
      });
      setLoading(false);
      return;
    }

    try {
      await notifications.sendNotification( title, message );
      
      setTitle('');
      setMessage('');
      showMessage({
        message: `Notificação enviada com sucesso!`,
        type: 'success',
        icon: 'success'
      });

    } catch (error) {
      showMessage({
        message: error,
        type: 'danger',
        icon: 'danger'
      });

    } finally {
      setLoading(false);

    }
  }, []);

  return (
    <View>
      

        <Label>Título</Label>
        <InputText
          value={title}
          onChangeText={text => setTitle(text)}
          autoCompleteType="off"
          autoFocus={true} />

        <Label>Mensagem</Label>
        <InputText
          multiline={true}
          numberOfLines={4}
          value={message}
          onChangeText={text => setMessage(text)}
          autoCompleteType="off"
          style={{
            height: 140
          }} />

        <GradientButton 
          title="Notificar todos os usuários" 
          onPress={handleSubmit} />

      
    </View>
  );
};
import React, { useRef } from 'react';
import { View, Animated, Dimensions, StyleSheet, FlatList } from 'react-native';
import { CarouselItem } from './Carouseltem';
import { Title } from '../Title';
import { SPACING } from '../../config/theme';
import { ICarouselData } from '../../services/interfaces';

export type CarouselProps = Omit<ICarouselData, "type">;

const { width } = Dimensions.get('window');
const ITEM_HEIGHT = 280 + 12 + 8;

export const Carousel = ({ title, items }: CarouselProps): JSX.Element => {

  const scrollX = new Animated.Value(0);
  let position = Animated.divide(scrollX, width);

  const flatListRef = useRef(null);

  return (
    <>
      <View style={styles.container}>
        <FlatList
          data={items}
          ref={flatListRef}
          keyExtractor={(_, index) => `key.${index}`}
          horizontal
          pagingEnabled
          scrollEnabled
          snapToAlignment="center"
          scrollEventThrottle={16}
          decelerationRate="fast"
          showsHorizontalScrollIndicator={false}
          renderItem={({ item }) => <CarouselItem image={item.image} caption={item.caption} />}
          onScroll={Animated.event(
            [{ nativeEvent: { contentOffset: { x: scrollX }} }],
            { useNativeDriver: false }
          )}
        />
      </View>

      <View style={styles.dotView}>
        {items.map((_, i) => {
          let opacity = position.interpolate({
            inputRange: [i - 1, i, i + 1],
            outputRange: [0.3, 1, 0.3],
            extrapolate: 'clamp'
          });
          return (
            <Animated.View
              key={i}
              style={{ opacity, ...styles.dot}}
            />
          )
        })}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container:{
    maxWidth: 600,
    marginTop: SPACING
  },
  dotView: { 
    flexDirection: 'row', 
    justifyContent: 'center',
    height: 12,
    marginTop: 8,
    marginBottom: SPACING * 2
  },
  dot: {
    width: 6, 
    height: 6, 
    backgroundColor: '#857D98', 
    marginHorizontal: 6, 
    marginVertical: 4, 
    borderRadius: 3
  }
})
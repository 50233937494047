export interface IChapterData {
  id: string,
  chapterId: number,
  title: string,
  slug: string,
  order: number,
  image: IChapterMedia,
  content?: IChapterItemData[]
}

export enum ChapterItemType{
  COVER = `cover`,
  PARAGRAPH = `paragraph`,
  PHOTO = `photo`,
  PERSON = `person`,
  TITLE = `title`,
  GRAPHIC = `graphic`,
  CAROUSEL = `carousel`
}

export interface IChapterMedia{
  id: string;
  uri: string;
  width: number;
  height: number;
  slug: string;
}

interface IChapterItemData {
  type: ChapterItemType | string,
}

export interface ICoverData extends IChapterItemData {
  index: string;
  title: string;
  subtitle?: string;
  image?: IChapterMedia;
}

export interface ITitleData extends IChapterItemData {
  content: string;
  level: 1 | 2 | 3 | 4;
}

export interface IParagraphData extends IChapterItemData {
  content: string;
}

export interface IPhotoData extends IChapterItemData {
  caption: string;
  image: IChapterMedia | undefined;
}

export interface IGraphicData extends IChapterItemData{
  title: string;
  image: IChapterMedia | undefined;
}

export interface IPersonData extends IChapterItemData{
  name: string,
  biography: string,
  image: IChapterMedia | undefined;
}

export interface ICarouselItemData {
  caption: string;
  image: IChapterMedia | undefined;
}

export interface ICarouselData extends IChapterItemData{
  title?: string;
  items: ICarouselItemData[];
}
import React, { useState } from 'react';
import AppLoading from 'expo-app-loading';
import { useFonts } from 'expo-font';
import { Montserrat_400Regular, Montserrat_600SemiBold, Montserrat_700Bold } from '@expo-google-fonts/montserrat';
import { Inter_400Regular, Inter_700Bold } from '@expo-google-fonts/inter';
import AppNavigator from './src/navigators/AppNavigator';
import { enableScreens } from 'react-native-screens';
import { Asset } from 'expo-asset';
import { getChapterCovers } from './src/data/helper';
import { slides } from './src/data/sliders';
enableScreens();

export default function App() {
  const [isLoadingComplete, setLoadingComplete] = useState(false);
  
  let [fontsLoaded] = useFonts({
    Montserrat_400Regular,
    Montserrat_600SemiBold,
    Montserrat_700Bold,
    Inter_400Regular,
    Inter_700Bold
  });
  
  if ( !fontsLoaded || !isLoadingComplete ) {
    return (
      <AppLoading
        startAsync={loadResourcesAsync}
        onError={handleLoadingError}
        onFinish={() => handleFinishLoading(setLoadingComplete)}  />
    )
  }

  return <AppNavigator />;
}

async function loadResourcesAsync() {
  const chaptersCovers: number[] = getChapterCovers();
  await Asset.loadAsync([
    require('./src/assets/splash.png'),
    require('./src/assets/bg-home.jpg'),
    require('./src/assets/bg-summary.jpg'),
    require('./src/assets/noimage.png'),
    ...chaptersCovers,
    ...slides
  ]);
}

function handleLoadingError(error) {
  // In this case, you might want to report the error to your error reporting
  // service, for example Sentry
  console.log(error);
}

function handleFinishLoading(setLoadingComplete) {
  setLoadingComplete(true);
}
import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { 
  ChapterScreen, 
  HomeScreen, 
  LoginScreen, 
  SummaryScreen, 
  PresentationScreen,
  ZoomImageScreen,
  ScannerScreen,
  ScannerWallScreen,
  SilentLoginScreen,
  NotificationsScreen,
  SendNotificationScreen,
  AdminScreen,
  RemoteControlScreen,
} from '../screens';
import { IChapterMedia } from '../services/interfaces';
import { colors } from '../config/theme';

export type RootStackParamList = {
  Home: undefined;
  Login: undefined;
  SilentLogin: undefined,
  Summary: undefined;
  Chapter: { slug: string, index: string };
  Presentation: undefined;
  Admin: undefined;
  ZoomImage: { image: IChapterMedia };
  Scanner: undefined,
  ScannerWall: undefined,
  Notifications: undefined, 
  SendNotifications: undefined,
  RemoteControl: undefined
};

declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList {}
  }
}

const titlePrefix = 'Relatório APL •';
const Stack = createNativeStackNavigator<RootStackParamList>();

const RootStack: React.FC = () => {
  return (
    <Stack.Navigator initialRouteName="Home">
      <Stack.Group screenOptions={{ 
          headerBackTitleVisible: false,
          headerTransparent: true,
          headerShown: false
        }}>
        <Stack.Screen 
          name="Home" 
          component={HomeScreen} 
          options={{ title: `Início` }} />
        <Stack.Screen 
          name="Summary" 
          component={SummaryScreen} 
          options={{ title: `Índice` }} />
        <Stack.Screen 
          name="Chapter" 
          component={ChapterScreen} 
          options={{ title: `Capítulo` }} />
        <Stack.Screen 
          name="Presentation" 
          component={PresentationScreen}
          options={{ title: `Apresentação` }} />
        <Stack.Screen 
          name="Scanner" 
          component={ScannerScreen} 
          options={{
            title: `Leia o QR Code`,
            headerTintColor: 'white',
            headerShown: true
          }} />
        <Stack.Screen 
          name="ScannerWall" 
          component={ScannerWallScreen} 
          options={{
            title: `Leia o QR Code`,
            headerTintColor: 'white',
            headerShown: true
          }} />
        <Stack.Screen 
          name="SilentLogin" 
          component={SilentLoginScreen} 
          options={{ title: `Autenticando...` }} />
      </Stack.Group>

      <Stack.Group screenOptions={{ 
          headerBackTitleVisible: false,
          headerTintColor: colors.primary,
          headerStyle: { 
            backgroundColor: colors.bg
          }
        }}>
        <Stack.Screen 
          name="Admin" 
          component={AdminScreen} 
          options={{ title: `Admin` }} />
        
        <Stack.Screen 
          name="Notifications" 
          component={NotificationsScreen} 
          options={{ title: `Boletim` }} /> 
        <Stack.Screen 
          name="SendNotifications" 
          component={SendNotificationScreen} 
          options={{ title: `Enviar notificação` }} /> 
      </Stack.Group>

      <Stack.Group screenOptions={{ 
        headerStyle: {
          backgroundColor: 'rgba(0,0,0,0.5)'
        },
        headerBackTitleVisible: false,
        headerTransparent: true,
        headerTintColor: 'white'
      }}>
        <Stack.Screen
          name="RemoteControl"
          component={RemoteControlScreen}
          options={{ 
            title: `Controle Remoto`,
          }} />
      </Stack.Group>

      <Stack.Group screenOptions={{ 
          presentation: 'modal',
          headerShown: false
        }}>
        <Stack.Screen 
          name="Login" 
          component={LoginScreen} 
          options={{ title: `Login` }} />
        <Stack.Screen 
          name="ZoomImage" 
          component={ZoomImageScreen}
          options={{ title: `Zoom` }} />
      </Stack.Group>
    </Stack.Navigator>
  )
}

export default RootStack;
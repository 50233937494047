import React from 'react';
import { StyleSheet, Text, TextProps } from 'react-native';
import { colors, fonts, SPACING } from '../../config/theme';
import { IParagraphData } from '../../services/interfaces';

export type ParagraphProps = Omit<IParagraphData, "type"> & TextProps

export const Paragraph = ({ content, ...rest }: ParagraphProps): JSX.Element => {
  return <Text style={styles.text} {...rest}>{content}</Text>;
}

const styles = StyleSheet.create({
  text: {
    fontFamily: fonts.montserrat.regular,
    fontSize: 16,
    color: colors.text,
    paddingHorizontal: SPACING * 1.5,
    paddingBottom: SPACING,
  }
});
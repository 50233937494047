import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { colors, fonts, getPhotoSizes, SPACING } from '../../config/theme';
import { getImageURI, parseHtmlEntities } from '../../data/helper';
import { ICarouselItemData } from '../../services/interfaces';

export type CarouselItemProps = ICarouselItemData;

const MAX_WIDTH = 600;

export const CarouselItem = ({ image, caption }: CarouselItemProps): JSX.Element => {

  const { width, height } = getPhotoSizes(image);

  return (
    <TouchableOpacity style={[styles.cardView, { width, height }]} activeOpacity={0.8}>
      <Image style={[styles.image, { width, height }]} source={getImageURI(image)} />
      <Text style={styles.caption}>{ parseHtmlEntities(caption) }</Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  cardView: {
    maxWidth: MAX_WIDTH,
    // width: ITEM_WIDTH,
    // height: ITEM_HEIGHT,
  },
  image: {
    maxWidth: MAX_WIDTH,
    // width: ITEM_WIDTH,
    // height: ITEM_HEIGHT,
  },
  caption: {
    marginTop: 6,
    paddingLeft: SPACING,
    fontFamily: fonts.montserrat.regular,
    fontSize: 13,
    color: colors.muted,
  }
});
import React from 'react';
import { ImageBackground, StyleSheet, Text, View } from 'react-native';

import { height, fonts, width, SPACING, colors, MAX_WIDTH } from '../../config/theme';
import { getImageURI } from '../../data/helper';
import { ICoverData } from '../../services/interfaces';

export type CoverProps = Omit<ICoverData, "type">;

export const Cover = ({ index, title, subtitle, image }: CoverProps): JSX.Element  => {
  return (
    <ImageBackground
      style={styles.container}
      source={getImageURI(image)}>

      <Text style={styles.index}>{index}</Text>

      <View style={styles.textContainer}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>{title}</Text>
        </View>
        {subtitle && <Text style={styles.subtitle}>{subtitle}</Text>}
      </View>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: MAX_WIDTH,
    width: '100%',
    height,
    justifyContent: 'flex-end',
    marginBottom: SPACING,
  },
  index:{
    position: "absolute",
    top: 50,
    right: 40,
    color: colors.primary,
    fontFamily: fonts.montserrat.bold,
    fontSize: 40
  },
  textContainer: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: width - 50,
    backgroundColor: 'rgba(102,74,140,0.8)',
    borderTopRightRadius: 26,
    paddingVertical: 30,
    paddingRight: 10
  },
  titleContainer:{
    width: '100%',
    borderLeftWidth: 10,
    borderLeftColor: colors.bg,
    paddingHorizontal: SPACING,
    marginBottom: SPACING,
  },
  title:{
    fontFamily: fonts.montserrat.bold,
    fontSize: 28,
    fontWeight: '800',
    color: colors.light,
    letterSpacing: -0.84,
    lineHeight: 38
  },
  subtitle: {
    fontFamily: fonts.inter.regular,
    fontSize: 16,
    color: colors.bg,
    paddingHorizontal: SPACING + 10,
  }
});
import React from 'react';
import { TouchableOpacityProps } from 'react-native';

import { IconContainer, CloseIcon } from './styles';

interface Props extends TouchableOpacityProps {
  dark?: boolean
}

export const CloseButton = ({ dark = false, ...props }: Props) => {
  return (
    <IconContainer dark={dark} {...props}>
      <CloseIcon dark={dark} />
    </IconContainer>
  );
}
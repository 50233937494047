import React from 'react';
import { ButtonProps } from 'react-native';

import { 
  ButtonContainer,
  ButtonBackground,
  Label,
  IconContainer,
  Arrow
} from './styles';

interface Props extends ButtonProps {
  icon?: React.ReactNode;
  colors?: string[]
}

export const GradientButton: React.FunctionComponent<Props> = ({
  title,
  onPress,
  icon,
  colors,
  ...props
}): JSX.Element => {
  return (
    <ButtonContainer onPress={onPress} {...props} activeOpacity={0.8}>
      <ButtonBackground 
        style={icon ? {} : { alignItems:'center', justifyContent: 'center' }}
        colors={colors ?? ['rgba(102, 74, 140 , 1)', 'rgba(102, 74, 140 , 0.75)'] }>

        {icon && <IconContainer>{icon}</IconContainer>}

        <Label>{title}</Label>

        {icon && <Arrow />}

      </ButtonBackground>
    </ButtonContainer>
  );
}